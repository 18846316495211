<div class="L-tableUI__tableWrapper" [ngClass]="tableWrapperClass">
    <div *ngIf="!dataSource?.data" class="h-[55vh] flex justify-center items-center">
        <p class="font-bold">Fetch list of records</p>
    </div>
    <div *ngIf="dataSource?.data && !dataSource.data.length" class="h-[55vh] flex justify-center items-center">
        <p class="font-bold">No Records Found</p>
    </div>

    <table #table id="grid" *ngIf="dataSource?.data?.length" mat-table [dataSource]="dataSource" (matSortChange)="announceSortChange($event)" class="L-tableUI__table" matSort [matSortActive]="matSortActive" [matSortDirection]="matSortDirection">
        <ng-container *ngFor="let column of initColumns; trackBy: customTrackBy" [matColumnDef]="column.key" [sticky]="column.value === 'checkbox'">
            <!-- kindly check styles for checkbox styling -->
            <ng-container *hasPermissions="column.perms">
                <ng-container *ngIf="column.type === 'checkbox'">
                    <th class="bg-background text-lg uppercase" mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="onCheckAll($event)" [checked]="masterCheckboxChecked" *ngIf="showCheckboxes"></mat-checkbox>
                    </th>
                    <td mat-cell class="text-sm text-center" *matCellDef="let item">
                        <!-- <mat-checkbox class="rounded-xl" (change)="onCheck($event, item)" [checked]="item.checked || false" *ngIf="showCheckboxes && !isPostingModule">
                    </mat-checkbox> -->

                        <mat-checkbox class="rounded-xl" #checkBoxes (change)="onCheck($event, item)" [checked]="item.checked || false" *ngIf="showCheckboxes" [disabled]="item['disableCheckbox']" [ngClass]="item['disableCheckbox'] ? 'greyCheck' : ''">
                        </mat-checkbox>
                        <!-- <mat-checkbox class="rounded-xl" #checkBoxes (change)="onCheck($event, item)" [checked]="item.checked || false" *ngIf="showCheckboxes && isPostingModule" [disabled]="
              (activeState !== 'pending' && 
              item['reversed']) ||
              item['transaction_type']?.toLowerCase() === 'reversal' ||
              (!item['reversed'] && item['reversal_transaction_id']) ||
              (item['transaction_type']?.toLowerCase() !== 'manual_entry' && !item['reversed'] && item['reversal_transaction_id']) ||
              (item['transaction_type']?.toLowerCase() !== 'manual_entry' && !item['reversed'])
            " [ngClass]="[
              (activeState !== 'pending' && item['reversed']) ||
              item['transaction_type']?.toLowerCase() === 'reversal' ||
              (!item['reversed'] && item['reversal_transaction_id']) ||
              (item['transaction_type']?.toLowerCase() !== 'manual_entry' &&
                !item['reversed'] &&
                item['reversal_transaction_id']) ||
              (item['transaction_type']?.toLowerCase() !== 'manual_entry' &&
                !item['reversed'])
                ? 'greyCheck'
                : ''
            ]">
                    </mat-checkbox> -->
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <span>
            {{ column.footer }}
          </span>
                    </td>
                </ng-container>
            </ng-container>


            <!-- if reversal -->
            <ng-container *ngIf="column.type === 'reversal'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" [ngClass]="{ 'text-red-500': item['reversed'] }" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{
              item[column.value].replace("_", " ")
                | truncate : column.maxLen
                | titlecase
            }}
            <span class="text-danger" *ngIf="item[column.isReversed] === true"
              >(reversed)</span
            >
          </span>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>
            <!-- if text -->
            <ng-container *ngIf="column.type === 'text'">
                <th class="w-[50px]" mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                    <span class="ml-[5px]" *ngIf="column.toolIcon" [matTooltip]="column.tooltip || ''" data-toggle="tooltip" data-placement="left">
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
                </th>
                <td mat-cell class="text-sm w-[50px]" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span [ngClass]="{
              'text-red-500':
                item['reversed'] ||
                (column.key === 'status' &&
                  item[column.value]?.toLowerCase() === 'outstanding'),
              'text-green-500':
                column.key === 'status' &&
                item[column.value]?.toLowerCase() === 'completed'
            }" [matTooltip]="item[column.value]">
            <!-- <ng-container *ngIf="item && column?.value"> -->
            {{
              item[column.value]?.toString() || "-"
                | underScoreToSpace
                | titlecase
                | truncate : column.maxLen
            }}
            <!-- </ng-container> -->
            <!-- {{ item[column.value] | truncate: column.maxLen | titlecase }} -->
          </span>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>
            <!-- if btn -->
            <ng-container *ngIf="column.type === 'btn'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span [ngClass]="{ 'text-red-500': item['reversed'] }" [matTooltip]="">
            <app-button-img-viewer
              [value]="item[column.value]"
            ></app-button-img-viewer>
          </span>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>
            <!-- if action btn -->
            <ng-container *ngIf="column.type === 'actionBtn'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" *matCellDef="let item" (click)="actionBtnTriggered.emit(item)">
                    <button class="p-3 min-w-[80px] bg-primary text-white rounded-xl opacity-90 border-none font-semibold mx-1">
            {{ column.key }}
          </button>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>
            <!-- if checkIcon -->
            <ng-container *ngIf="column.type === 'checkIcon'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm text-center" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <mat-icon *ngIf="item[column.value]" class="text-success !w-[15px] !h-[15px]" svgIcon="check"></mat-icon>
                    <mat-icon *ngIf="!item[column.value]" class="text-error !w-[15px] !h-[15px]" svgIcon="cross"></mat-icon>
                </td>
                <td class="text-center" mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>
            <!-- if email -->
            <ng-container *ngIf="column.type === 'email'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" [ngClass]="{ 'text-red-500': item['reversed'] }" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen }}
          </span>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>
            <!-- if boolean -->
            <ng-container *ngIf="column.type === 'boolean'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" [ngClass]="column.class" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span [ngClass]="{ 'text-red-500': item['reversed'] }" [matTooltip]="item[column.value]">
            <ng-container
              *ngIf="column.value === 'reversed'; else defaultBoolean"
            >
              <span>
                {{
                  item[column.value]
                    | booleanReversedValues : item["reversal_transaction_id"]
                }}</span
              >
            </ng-container>

            <ng-template #defaultBoolean>
              {{ item[column.value] | booleanValues }}
            </ng-template>
          </span>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>
            <!-- if object access key in objectVal -->
            <ng-container *ngIf="column.type === 'object'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | findValInObject : column.objectVal }}
          </span>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>
            <!-- if number, use commas -->
            <ng-container *ngIf="column.type === 'number'">
                <th class="floatRight" style="text-align: right" mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                    <span class="ml-[5px]" *ngIf="column.toolIcon" [matTooltip]="column.tooltip || ''" data-toggle="tooltip" data-placement="left">
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | numberWithComma }}
          </span>
                </td>
                <td style="text-align: right" class="floatRight" mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer | numberWithComma }}
          </span>
                </td>
            </ng-container>

            <!-- if numberWithDecimal, use commas and two place decimal -->
            <ng-container *ngIf="column.type === 'numberWithDecimal'">
                <th style="text-align: right" class="floatRight" mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                    <span class="ml-[5px]" *ngIf="column.toolIcon" [matTooltip]="column.tooltip || ''" data-toggle="tooltip" data-placement="left">
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span [ngClass]="{ 'text-red-500': item['reversed'] }" [ngClass]="item?.class">
            {{ item[column.value] | numberWithCommaAndTwoDecimal }}
          </span>
                </td>
                <td mat-footer-cell *matFooterCellDef style="text-align: right">
                    <span>
            {{ column.footer | numberWithCommaAndTwoDecimal }}
          </span>
                </td>
            </ng-container>
            <!-- if id -->
            <ng-container *ngIf="column.type === 'id'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] }}
          </span>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                    <span>
            {{ column.footer }}
          </span>
                </td>
            </ng-container>

            <!-- if currency or percentile -->
            <ng-container *ngIf="column.type === 'currency_percentile'">
                <th class="floatRight text-right" mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                    <span class="ml-[5px]" *ngIf="column.toolIcon" [matTooltip]="column.tooltip || ''" data-toggle="tooltip" data-placement="left">
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
                </th>

                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <ng-container *ngIf="item?.chargeType; else formatCurrency">
                        <span *ngIf="item?.chargeType === 'FLAT'; else percentFormat">
              {{ item[column.value] | wz_naira_money_format }}
            </span>
                        <ng-template #percentFormat>
                            {{ item[column.value] | wz_percentage_format_2 }}
                        </ng-template>
                    </ng-container>

                    <ng-template #formatCurrency>
                        {{ item[column.value] | wz_naira_money_format }}
                    </ng-template>
                </td>
            </ng-container>
            <!-- if currency format with symbol -->
            <ng-container *ngIf="column.type === 'currency'">
                <th class="floatRight" mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name | wz_money_symbol }}
          </span>
                    <span class="ml-[5px]" *ngIf="column.toolIcon" [matTooltip]="column.tooltip || ''" data-toggle="tooltip" data-placement="left">
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            <ng-container *ngIf="item?.chargeType; else formatCurrency">
              <span *ngIf="item?.chargeType === 'FLAT'; else percentFormat">
                {{ item[column.value] | wz_money_format }}
              </span>
                    <ng-template #percentFormat>
                        {{ item[column.value] | wz_percentage_format_2 }}
                    </ng-template>
            </ng-container>

            <ng-template #formatCurrency>
                {{ item[column.value] | wz_money_format }}
            </ng-template>
            </span>
            </td>
            <td mat-footer-cell *matFooterCellDef style="text-align: right">
                <span>
            {{ column.footer | wz_money_format }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'percentile'">
            <th class="floatRight" mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | wz_percentage_format_2 }}
          </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <span>
            {{ column.footer }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'date'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
                <span class="ml-[5px]" *ngIf="column.toolIcon" [matTooltip]="column.tooltip || ''" data-toggle="tooltip" data-placement="left">
            <mat-icon
              class="text-secondary !w-[18px] !h-[18px]"
              svgIcon="info"
            ></mat-icon>
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | date : "dd-MM-YYYY": "UTC" }}
          </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <span>
            {{ column.footer }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'dateTime'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | date : "dd-MM-YYYY h:mm:ss a": "UTC" }}
          </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <span>
            {{ column.footer }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'dateTimeMeridian'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | date : "dd-MM-YYYY hh:mm a": "UTC" }}
          </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <span>
            {{ column.footer }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'customDateSelect'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <mat-form-field class="w-full" [ngClass]="{ 'text-red-500': item['reversed'] }" appearance="outline">
                    <input readonly matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="item[column.value]" />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <span>
            {{ column.footer }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'phone'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ item[column.value] | wz_phone_number }}
          </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <span>
            {{ column.footer }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'multiValue'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span [ngClass]="{ 'text-red-500': item['reversed'] }">
            {{ column.values | mergeMultiValues : item | titlecase }}
          </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <span>
            {{ column.footer }}
          </span>
            </td>
        </ng-container>

        <!-- <ng-container *ngIf="column.type === 'enum'">
          <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
              <span>
          {{ column.name }}
        </span>
          </th>
          <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
              <span [ngClass]="{ 'text-red-500': item['reversed'] }">
          {{ item[column.value]?.replace('_', ' ') }}
        </span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
              <span>
          {{ column.footer }}
        </span>
          </td>
      </ng-container> -->

        <!-- if more (...) -->
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <ng-container *ngIf="!usePermission">
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </ng-container>
        <ng-container *ngIf="usePermission">
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.hidden]="!hasPermission(row.perms)"></tr>
        </ng-container>
        <ng-container *ngIf="showFooter">
            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="font-semibold"></tr>
        </ng-container>
    </table>
</div>
<app-pagination-ui (togglePageControlEmit)="onPageCtrlToggle($event)" (pageSizeToggleEmit)="onPageSizeToggle($event)" [showPagination]="showPagination" [pageProp]="pageProp"></app-pagination-ui>