import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Permissions } from '../shared/dto/permissions';

@Injectable({
  providedIn: 'root',
})

export class PermissionService {
  private permissions = Permissions;
  private usersPermissions: BehaviorSubject<typeof Permissions | {}> =
    new BehaviorSubject({});

  constructor() {
  }

  public get UsersPermissions() {
    return this.usersPermissions.value;
  }
  public get UsersPermissions$() {
    return this.usersPermissions.asObservable();
  }
  public setUsersPermissions(perm: typeof Permissions | {}) {
    this.usersPermissions.next(perm);
  }

  public evaluateUsersPermissions() {
    const permissions: string[] = JSON.parse(
      localStorage.getItem('userPerm') || '[]'
    );

    const permissionsObj: typeof Permissions | {} = {};
    const selectedPermissions = {};
    permissions.forEach((perm) => {
      const trimedPerm = perm.trim()
      selectedPermissions[trimedPerm] = trimedPerm;
    });

    Object.entries(this.permissions).forEach((val) => {
      if (selectedPermissions[val[1]]) {
        permissionsObj[val[1]] = true;
      }
    });

    this.setUsersPermissions(permissionsObj);

  }

  public hasPermissionList(perms: string[]): boolean {
    const permsList = []
    if(!perms || !perms.length) return false
    for (let i = 0; i < perms.length; i++) {
      if(perms[i] === Permissions.IS_FRANCHISEE){
        return this.isFranchisee;
      }
      if (perms[i] === Permissions.NONE) return true;
      if (this.usersPermissions.value[perms[i]]){
        permsList.push(perms[i]);
      };
      
    }
    if(permsList.length) return true;
    return false;
  }

  private get isFranchisee():boolean{
    // logic to evaluate if current user is a franchisee
    return false;
  }

  // public generatePerms() {
  //   const perms = [
  //     Permissions.ACTIVATE_CLIENT,
  //     Permissions.APPROVE_CLIENT,
  //     Permissions.RUN_LOAN_REPORTS,
  //     Permissions.APPROVE_CLIENT_LOAN_ACCOUNT,
  //   ];
  //   setTimeout(() => {
  //     localStorage.setItem('userPerm', JSON.stringify(perms));
  //     this.evaluateUsersPermissions();
  //   }, 10000);
  // }
}
