import { Pipe, PipeTransform } from '@angular/core';
import findValFromObj from '../shared/helperFunctions/evalValFromObj';
import { ColumnType } from '../shared/models/table';
import {
  TitleCasePipe,
  formatCurrency,
  getCurrencySymbol,
} from '@angular/common';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';



@Pipe({
  name: 'ordinalDate',
})
export class OrdinalDatePipe implements PipeTransform {
  constructor(private utilsService: UtilityService) {}

  transform(value: Date | string): string {
    if (!value) {
      return '';
    }
    value = new Date(value)
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    

    return `${value.getDate()}${this.utilsService.nth(value.getDate())} day of ${
      months[value.getMonth()]
    } ${value.getFullYear()}`;
  }
}



@Pipe({ name: 'Title_Case' })
export class TiteCaseFormat implements PipeTransform {
  constructor(private titlecase: TitleCasePipe) {}

  transform(value: any) {
    if (value === null || value === '') return '';

    if (typeof value === 'string') {
      const newVal = value.replace(/-/g, ' ');
      const titleCasedValues = newVal
        .split(' ')
        .map((val) => this.titlecase.transform(val));

      return titleCasedValues.join(' ');
    }

    if (Array.isArray(value))
      return value.map((value) => {
        if (typeof value === 'string') {
          const newVal = value.replace(/-/g, ' ');
          const titleCasedValues = newVal
            .split(' ')
            .map((val) => this.titlecase.transform(val));

          return titleCasedValues.join(' ');
        }
        return value;
      });
  }
}

@Pipe({ name: 'underScoreToSpace' })
export class UnderScoreToSpacePipe implements PipeTransform {
  transform(values: any): any {
    if (typeof values !== 'string' && !Array.isArray(values)) return values;

    if (typeof values === 'string') return values.replace(/_/g, ' ');

    if (Array.isArray(values))
      return values.map((value) => {
        if (typeof value === 'string') {
          return value.replace(/_/g, ' ');
        }
        return value;
      });
  }
}
@Pipe({ name: 'getLabel' })
export class GetLabelPipe implements PipeTransform {
  transform(value, valueFieldName, items, key): any {
    const item = items.find((record) => record[valueFieldName] === value)
    return item && key ? item[key] : ''
  }
}

@Pipe({ name: 'filterGlAccounts' })
export class FilterGlAccountsPipe implements PipeTransform {
  transform(glAccounts: any[], selectedAccountId: number | null): any[] {
    if (!selectedAccountId) {
      return glAccounts;
    }

    return glAccounts.filter((glAccount) => glAccount.id !== selectedAccountId);
  }
}

@Pipe({ name: 'filterGlAccountName' })
export class FilterGlAccountNamePipe implements PipeTransform {
  transform(glAccounts: any[], selectedAccountId: number | null): string {
    if (!selectedAccountId) {
      return '';
    }

    const selectedGlAccount = glAccounts.find(
      (glAccount) => glAccount.id === selectedAccountId
    );
    return selectedGlAccount.name;
  }
}

@Pipe({ name: 'wz_currency' })
export class MycurrencyPipe implements PipeTransform {
  transform(
    value: number | string,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo: string = '1.2-2'
  ): string | null {
    if (!value || !Number(value)) return '-';
    const locale =
      localStorage.getItem('locale') || environment.countryInfo.locale;
    const currencyCode = environment.countryInfo.code || 'NGN';
    const currencySymbol = getCurrencySymbol(currencyCode, 'narrow');

    return formatCurrency(
      Number(value),
      locale,
      currencySymbol,
      currencyCode,
      digitsInfo
    );
    // return formatCurrency(
    //   value,
    //   locale,
    //   getCurrencySymbol(currencyCode, 'wide'),
    //   currencyCode,
    //   digitsInfo,
    // );
  }
}

@Pipe({ name: 'wz_general_format' })
export class GeneralFormat implements PipeTransform {
  transform(value: any, dataType: ColumnType, ...args: any[]): any {
    switch (dataType) {
      case ColumnType.MONEY:
        return parseFloat(value + '')
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      case ColumnType.DECIMAL:
        return parseFloat(value + '')
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      case ColumnType.PERCENTAGE:
        return (
          parseFloat(value + '')
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '%'
        );
      default:
        return value;
    }
  }
}

@Pipe({ name: 'wz_money_symbol' })
export class MoneySymbolFormat implements PipeTransform {
  transform(value: string): any {
    if (!value) return '-';
    const symbol = '₦';
    return `${value} (${symbol})`;
  }
}

@Pipe({ name: 'wz_money_format' })
export class MoneyFormat implements PipeTransform {
  transform(value: number | string, args?: string[]): any {
    if (!value || !Number(value)) return '-';
    return parseFloat(Number(value) + '')
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
}

@Pipe({ name: 'wz_naira_money_format' })
export class MoneyFormatInNaira implements PipeTransform {
  transform(value: number | string): any {
    if (!value || !Number(value)) return '-';
    let prefix = '';

    if (Number(value) <= 0) {
      prefix = '-';
    }
    const number = Math.abs(Number(value));
    if (number >= 1000000000) {
      return (
        '₦' +
        (number / 1000000000).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') +
        'bn'
      );
      // } else if (number >= 1000000) {
      //   return '₦' +(number / 1000000).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + 'M';
    } else {
      return (
        '₦' +
        prefix +
        parseFloat(number + '')
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      );
    }
  }
}

@Pipe({ name: 'wz_naira_no_decimal_money_format' })
export class NoDecimalMoneyFormatInNaira implements PipeTransform {
  transform(value: number, args: string[]): any {
    if (!value) return '-';
    return '₦' + parseFloat(value + '').toLocaleString();
  }
}

@Pipe({ name: 'wz_percentage_format' })
export class PercentageFormat implements PipeTransform {
  transform(value: number): any {
    if (!value) return '-';
    if (isNaN(value)) return '-';
    return value.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '%';
  }
}

@Pipe({ name: 'wz_percentage_format_1' })
export class PercentageFormat1 implements PipeTransform {
  transform(value: number): any {
    if (!value) return '-';
    return value.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
}

@Pipe({ name: 'wz_percentage_format_2' })
export class PercentageFormat2 implements PipeTransform {
  transform(value: number): any {
    if (value === undefined || value === null) return '-';
    return (
      String(Number(String(value).replace(/[^0-9.]/g, '')).toFixed(2)) + '%'
    );
    // return value.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,' + '%');
  }
}
@Pipe({ name: 'wz_percentage_format_4' })
export class PercentageFormat4 implements PipeTransform {
  transform(value: number): any {
    if (value === undefined || value === null) return '-';
    return (
      String(Number(String(value).replace(/[^0-9.]/g, '')).toFixed(3)) + '%'
    );
    // return value.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,' + '%');
  }
}

@Pipe({ name: 'wz_percentage_format_3' })
export class PercentageFormat3 implements PipeTransform {
  transform(value: number): any {
    if (value === undefined || value === null) return '-';
    return (
      String(Number(String(value).replace(/[^0-9.]/g, '')).toFixed(1)) + '%'
    );
    // return value.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,' + '%');
  }
}

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      value = value.replace(/_/g, ' ');
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
    return value;
  }
}

@Pipe({ name: 'formatMilliBilli' })
export class FormatTotalNumber implements PipeTransform {
  transform(
    value: number | string,
    digitsInfo: string = '1.2-2',
    hasCurrencySymbol: boolean = true
  ): any {
    if (!value || !Number(value) || Number(value) <= 0) return '-';
    value = Number(value);
    const locale =
      localStorage.getItem('locale') || environment.countryInfo.locale;
    const currencyCode = environment.countryInfo.code || 'NGN';
    const currencySymbol = getCurrencySymbol(currencyCode, 'narrow');

    if (Number(value) >= 1e9) {
      // Format as billions
      return hasCurrencySymbol
        ? `${currencySymbol}${(Number(value) / 1e9).toFixed(1)}b`
        : `${(Number(value) / 1e9).toFixed(1)}b`;
    } else if (Number(value) >= 1e6) {
      // Format as millions
      return hasCurrencySymbol
        ? `${currencySymbol}${(Number(value) / 1e6).toFixed(1)}m`
        : `${(Number(value) / 1e6).toFixed(1)}m`;
    } else {
      // Default format
      if (hasCurrencySymbol) {
        return formatCurrency(
          Number(value),
          locale,
          currencySymbol,
          currencyCode,
          digitsInfo
        );
      } else {
        const formattedValue = value
          .toFixed(0)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return `${formattedValue}`;
      }
    }
  }
}
@Pipe({ name: 'wz_counter_format' })
export class CounterFormat implements PipeTransform {
  transform(value: number, args: string[]): any {
    if (!value) return '00';
    if (value < 10) return '0' + value;
    return value + ''.replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
}

@Pipe({ name: 'minusValues' })
export class MinusValuesPipe implements PipeTransform {
  transform(values: string[], arg1): any {
    if (!values || !values.length) return '-';
    return (
      values.reduce((acc, val, index) => {
        if (index === 0) {
          return Number(arg1[val]);
        }
        return Number(acc) - Number(arg1[val]);
      }, 0) || '-'
    );
  }
}

@Pipe({ name: 'wz_phone_number' })
export class PhoneNumberPipe implements PipeTransform {
  public new_number: any;
  transform(value: number): any {
    return value;
  }
}

@Pipe({ name: 'wz_thousand_suffix' })
export class ThousandSuffixPipe implements PipeTransform {
  transform(
    input: any,
    args?: any,
    isAmount?: boolean,
    isNumericTIle?: boolean
  ): any {
    var exp,
      rounded,
      suffixes = ['k', 'm', 'b', 't', 'q', 'e'];

    // if it is not a number
    if (Number.isNaN(input)) {
      return null;
    }

    // if input is null or is equal to 0
    if (input == null || input == 0) {
      return '-';
    }

    exp = Math.floor(Math.log(input) / Math.log(1000));

    if (isNumericTIle) {
      if (input > 99999) {
        return (
          (isAmount ? '₦' : '') +
          (input / Math.pow(1000, exp)).toFixed(args) +
          suffixes[exp - 1]
        );
      }
      if (isAmount) {
        return (
          '₦' +
          parseFloat(input + '')
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        );
      } else {
        return Number(input).toLocaleString();
      }
    } else {
      if (input < 1000) {
        if (args) {
          return (
            (isAmount ? '₦' : '') +
            input.toFixed(args).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
          );
        }
        return (isAmount ? '₦' : '') + Number(input).toLocaleString();
      }
    }
    return (
      (isAmount ? '₦' : '') +
      (input / Math.pow(1000, exp)).toFixed(args) +
      suffixes[exp - 1]
    );
  }
}

@Pipe({ name: 'truncate' })
export class Truncate implements PipeTransform {
  transform(value: any, count?: number) {
    if (!value || value === 'null') return '';
    let maxLimit = count ?? 20;
    if (value.length > maxLimit) {
      let a = value.toString().substr(0, maxLimit);
      return `${a}...`;
    }
    return value;
  }
}

@Pipe({ name: 'sentenceCase' })
export class SentenceCase implements PipeTransform {
  transform(value: any) {
    if (value) {
      value = value.replace(/_/g, ' ');
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
    return value;
  }
}

@Pipe({ name: 'determineDay' })
export class DetermineDayPipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date();
    const date1 = new Date();
    const dateString = date.toString();
    const month =
      date1.getMonth() + 1 <= 9
        ? `0${date1.getMonth() + 1}`
        : date1.getMonth() + 1;
    const yesterday = parseInt(dateString.slice(8, 10), 10) - 1;
    const todaysDate = `${dateString.slice(8, 10)}-${month}-${dateString.slice(
      11,
      15
    )}`;
    const yesterdaysDate = `${dateString.slice(11, 15)}-${month}-${yesterday}`;
    if (value == todaysDate) {
      return 'Today';
    }
    if (value == yesterdaysDate) {
      return 'Yesterday';
    }

    return value;
  }
}

@Pipe({ name: 'numberWithComma' })
export class NumberWithCommas implements PipeTransform {
  transform(value: number | string): string {
    if (!value || !Number(value)) return '-';
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
@Pipe({ name: 'numberWithCommaAndTwoDecimal' })
export class NumberWithCommasAndTwoDecimal implements PipeTransform {
  transform(value: number): string {
    if (!value) return '-';
    const formattedValue = value.toFixed(2);
    return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

@Pipe({
  name: 'twoDigitNumber',
})
export class TwoDigitNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) return '-';
    return value.toString().padStart(2, '0');
  }
}

@Pipe({ name: 'findValInObject' })
export class FindValInObject implements PipeTransform {
  transform(object: any, key: string | string[]): string {
    if(typeof key === 'string') {
      return findValFromObj(object, key);
    }
    return key.reduce((acc, k) => {
      const val = findValFromObj(object, k);
      acc += ` ${val}`;
      return acc
    }, ``)
  }
}

@Pipe({ name: 'mergeMultiValues' })
export class MergeMultiValues implements PipeTransform {
  transform(values: string[] | string, obj: any, joinSymbol?: string): string {
    if (!values) return '';
    if (Array.isArray(values)) {
      let val = values.reduce((acc, cur, i) => {
        if(i !== 0){
          acc += `${joinSymbol ? ` ${joinSymbol} ` : ''}${obj[cur]}`
        }else{
          acc += `${obj[cur]}`
        }
        return acc
      }, '');
      
      return val;
    }
    return obj[values];
  }
}

@Pipe({ name: 'booleanValues' })
export class BooleanValues implements PipeTransform {
  transform(values: boolean): string {
    return values ? 'Yes' : 'No';
  }
}

@Pipe({ name: 'booleanReversedValues' })
export class BooleanReversedValues implements PipeTransform {
  transform(values: boolean, reversalId: string): string {
    if (!values && reversalId) return 'Pending';
    if (values && !reversalId) return 'Yes';
    if (values && reversalId) return 'Yes';
    if (!values && !reversalId) return 'No';
  }
}

@Pipe({ name: 'ellipses' })
export class EllipsesPipe implements PipeTransform {
  transform(value: any) {
    if (value.length > 30) {
      let a = value.toString().substr(0, 30);
      return `${a}...`;
    }
    return value;
  }
}

@Pipe({ name: 'permissionsNamePipe' })
export class PermissionsNamePipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      return value.replace(/-/g, ' ');
    }
    return value;
  }
}

@Pipe({ name: 'selectedAssetPipe' })
export class SelectedAssetPipe implements PipeTransform {
  transform(assets: any[], assetId: number) {
    return assets.find((asset) => asset.id === assetId)?.name || '';
  }
}

@Pipe({ name: 'selectedLiabilitiesPipe' })
export class SelectedLiabilityPipe implements PipeTransform {
  transform(liabilities: any[], liabilityId: number) {
    return (
      liabilities.find((liability) => liability.id === liabilityId)?.name || ''
    );
  }
}

@Pipe({ name: 'selectedExpensePipe' })
export class SelectedExpensePipe implements PipeTransform {
  transform(expenses: any[], expenseId: number) {
    return expenses.find((expenses) => expenses.id === expenseId)?.name || '';
  }
}

@Pipe({ name: 'selectedIncomePipe' })
export class SelectedIncomePipe implements PipeTransform {
  transform(incomes: any[], incomeId: number) {
    return incomes.find((income) => income.id === incomeId)?.name || '';
  }
}

@Pipe({ name: 'filterDisbursementFundsourcePipe' })
export class FilterDisbursementFundsourcePipe implements PipeTransform {
  transform(loanProductId: number, loanProducts: any[]) {
    return loanProducts.find(
      (loanProduct) => loanProduct.value === loanProductId
    )?.fundSources;
  }
}

@Pipe({ name: 'valueIsNegativePipe' })
export class ValueIsNegativePipe implements PipeTransform {
  transform(value: number | string): boolean {
    return (typeof value === 'number') ? String(value)?.includes('-') : value?.includes('-');
  }
}

@Pipe({
  name: 'stripNegativeSignPipe',
})
export class StripNegativeSignPipe implements PipeTransform {
  transform(value: number | string): string {
    return (typeof value === 'number') ? String(value)?.replace('-', '') : value?.replace('-', '');
  }
}
