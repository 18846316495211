<div class="L-tableUI__tableWrapper">
    <div *ngIf="!dataSource?.data" style="height: 55vh" class="flex justify-center items-center">
        <p class="font-bold">Fetch list of records</p>
    </div>
    <div *ngIf="dataSource?.data && !dataSource.data.length" style="height: 55vh" class="flex justify-center items-center">
        <p class="font-bold">No Records found</p>
    </div>
    <table #table id="grid" *ngIf="dataSource?.data?.length" mat-table [dataSource]="dataSource" (matSortChange)="announceSortChange($event)" class="L-tableUI__table" matSort [matSortActive]="matSortActive" [matSortDirection]="matSortDirection">
        <ng-container *ngFor="let column of initColumns" [matColumnDef]="column.key" [sticky]="column.value === 'checkbox'">
            <!-- kindly check styles for checkbox styling -->
            <ng-container *ngIf="column.type === 'checkbox'">
                <th class="bg-background text-lg uppercase" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="onCheckAll($event)" [checked]="masterCheckboxChecked" *ngIf="showCheckboxes"></mat-checkbox>
                </th>
                <td mat-cell class="text-sm" *matCellDef="let item">
                    <mat-checkbox class="pr-3" (change)="onCheck($event, item)" [checked]="item.checked || false" *ngIf="showCheckboxes">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- if reversal -->
            <ng-container *ngIf="column.type === 'reversal'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen | titlecase }}
            <span class="text-danger" *ngIf="item[column.isReversed] === true"
              >(reversed)</span
            >
          </span>
                </td>
            </ng-container>
            <!-- if text -->
            <ng-container *ngIf="column.type === 'text'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen | titlecase }}
          </span>
                </td>
            </ng-container>
            <!-- if btn -->
            <ng-container *ngIf="column.type === 'btn'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="">
            <app-button-img-viewer
              [value]="item[column.value]"
            ></app-button-img-viewer>
          </span>
                </td>
            </ng-container>
            <!-- if email -->
            <ng-container *ngIf="column.type === 'email'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen }}
          </span>
                </td>
            </ng-container>
            <!-- if boolean -->
            <ng-container *ngIf="column.type === 'boolean'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm" [ngClass]="column.class" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | booleanValues }}
          </span>
                </td>
            </ng-container>
            <!-- if object access key in objectVal -->
            <ng-container *ngIf="column.type === 'object'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="">
            {{ item[column.value] | findValInObject : column.objectVal }}
          </span>
                </td>
            </ng-container>
            <!-- if number, use commas -->
            <ng-container *ngIf="column.type === 'number'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="">
            {{ item[column.value] | numberWithComma }}
          </span>
                </td>
            </ng-container>
            <!-- if id -->
            <ng-container *ngIf="column.type === 'id'">
                <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="">
            {{ item[column.value] }}
          </span>
                </td>
            </ng-container>
            <!-- if currency format with symbol -->
            <ng-container *ngIf="column.type === 'currency'">
                <th class="floatRight" mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)" style="text-align: right">
                    <span class="">
            <ng-container *ngIf="item?.chargeType; else formatCurrency">
              <span *ngIf="item?.chargeType === 'FLAT'; else percentFormat">
                {{ item[column.value] | wz_currency }}
              </span>
                    <ng-template #percentFormat>
                        {{ item[column.value] | wz_percentage_format_2 }}
                    </ng-template>
            </ng-container>

            <ng-template #formatCurrency>
                {{ item[column.value] | wz_currency }}
            </ng-template>
            </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'percentile'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | wz_percentage_format_2 }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'date'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | date : "dd/MM/yyyy" : "UTC" }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'dateTime'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | date : "dd-MM-YYYY h:mm:ss a" : "UTC" }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'dateTimeMeridian'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | date : "dd-MM-YYYY hh:mm a" : "UTC" }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'customDateSelect'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <mat-form-field class="w-[50%]" appearance="outline">
                    <input readonly [disabled]="true" matInput [min]="minDate" [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="
                item[column.value] = $event.value.format('YYYY-MM-DD')
              " [value]="utils.formatDate_custom_yearFirst(item[column.value])" />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container *ngIf="column.type === 'customLoanOfficerSelectPicker'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm flex items-center gap-2" *matCellDef="let item; let i = index">
                <app-loan-officer-search-select class="w-full" [disabled]="item.hasLoanOfficer" [areaId]="item.branch?.area?.id" [branchId]="item.branch?.id" (emitSelectedVal)="
              item[column.key] = $event?.id ?? item[column.key];
              leaderLoanOfficerAccountId = 0
            " [value]="item[column.value]"></app-loan-officer-search-select>
                <button class="btn btn-sm btn-primary" style="margin-left: 24px" (click)="loanOfficerApplyToAll(item, column, i)">
            Apply to all
          </button>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'customGlAccountSelectPicker'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item; let i = index">
                <mat-form-field appearance="outline" class="w-[50%]">
                    <mat-select [(value)]="item[column.value]" #glAccountSelect>
                        <mat-option *ngFor="
                  let account of item.branch?.branchGls || item.branchGls || []
                " [value]="account.id">
                            {{ account.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="btn btn-sm {{
              leaderAccountId === item.id ? 'btn-primary' : 'btn-secondary'
            }}" style="margin-left: 24px" (click)="glAccountApplyToAll(item, column, i)">
            Apply to all
          </button>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'customSelectPicker'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item; let i = index" (click)="triggerRowClick(item)">
                <mat-form-field appearance="outline" class="w-[50%]">
                    <mat-select [(value)]="item.fundSourceGlId" #fundSourceSelect>
                        <mat-option *ngFor="
                  let fundSource of item.productId
                    | filterDisbursementFundsourcePipe : loanProducts
                " [value]="fundSource.glAccountId">
                            {{ fundSource.glAccountName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="btn btn-sm {{
              leaderAccountId === item.id ? 'btn-primary' : 'btn-secondary'
            }}" style="margin-left: 24px" (click)="applyToAll(item.accountId, i)">
            Apply to all
          </button>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'customInput'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item; let i = index">
                <mat-form-field appearance="outline" class="w-[50%]">
                    <input type="tel" matInput appCurrency [(ngModel)]="item[column.value]" />
                </mat-form-field>
                <button class="btn btn-sm btn-primary btn-secondary " style="margin-left: 24px" (click)="applyToAllCustomInput(item[column.value], column.value)">
                Apply to all
              </button>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'phone'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | wz_phone_number }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'multiValue'">
            <th mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-sm text-right" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ column.values | mergeMultiValues : item | titlecase }}
          </span>
            </td>
        </ng-container>

        <!-- if more (...) -->
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <ng-container *ngIf="!usePermission">
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </ng-container>
        <ng-container *ngIf="usePermission">
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.hidden]="!hasPermission(row.perms)"></tr>
        </ng-container>
    </table>
</div>
<app-pagination-ui (togglePageControlEmit)="onPageCtrlToggle($event)" (pageSizeToggleEmit)="onPageSizeToggle($event)" [showPagination]="showPagination" [pageProp]="pageProp"></app-pagination-ui>